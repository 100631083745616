<template>
  <div>
    <navbar title="补贴培训"></navbar>
    <img
      :src="img[3].picture"
      alt=""
      class="header_img"
      v-if="img.length > 0"
    />
    <div class="title">
      <div class="k"></div>
      <div>社会化补贴申请联系地址</div>
    </div>
    <div class="search" @click="show = true">
      <img src="../assets/qy.jpg" alt="" />
      <div class="qy">区域</div>
      <span>{{ area || "请选择区域" }}</span>
    </div>
    <div class="list" v-if="list.length > 0">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="top">
          <div class="top_1">
            <img src="../assets/to.png" alt="" />
            <span> {{ item.name }}</span>
          </div>
          <div class="top_2">
            <img src="../assets/dianwei.jpg" alt="" />
            <span>{{ item.distance }}km</span>
          </div>
        </div>
        <div class="center">
          <div class="center_1">
            <img :src="item.picture || src" alt="" />
          </div>
          <div class="center_2">
            时间：工作日：{{ item.weekdays || "暂无时间" }} <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;周末及假期：{{
              item.holiday || "暂无时间"
            }}<br />
            地址：{{ item.area }} {{ item.address }}
          </div>
        </div>
        <div class="footer">
          <div class="footer_1" @click="daohang(item)">
            <img src="../assets/daohang.jpg" alt="" />
            路线
          </div>
          <a class="footer_2" :href="`tel:${item.phone}`">
            <img src="../assets/dianhua.jpg" alt="" />
            {{ item.phone }}
          </a>
          <div class="footer_3" @click="collection(item)">
            <img src="../assets/sc3.png" alt="" />
            {{ item.collection == 0 ? "收藏" : "取消" }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="tac">—— 暂无内容 ——</div>
    <van-popup v-model="show" position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择地区"
        :options="cityss"
        @close="show = false"
        @finish="onFinish"
        :field-names="fieldNames"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import map from "../libs/map";
import { city } from "../libs/city";
export default {
  data() {
    return {
      src: require("../assets/mr1.png"),
      img: [],
      cityss: [],
      lat: "",
      lng: "",
      area: "",
      list: [],
      show: false,
      cascaderValue: "",
      fieldNames: {
        text: "label",
        value: "label",
        children: "children",
      },
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
    }),
  },
  methods: {
    ...mapActions([
      "getbanner",
      "getsocial_subsidy",
      "getcollection",
      "getcancel_collection",
    ]),
    onFinish({ selectedOptions }) {
      let that = this;
      this.show = false;
      this.area = selectedOptions.map((option) => option.label).join(",");
      that
        .getsocial_subsidy({
          lat: that.lat,
          lng: that.lng,
          area: that.area,
          member_id: that.userinfo.id,
        })
        .then((res) => {
          if (res.SuccessCode == 200) {
            that.list = res.ResponseBody;
          }
        });
    },
    city() {
      map.init().then((BMap) => {
        const locationCity = new BMap.Geolocation();
        var that = this;
        locationCity.getCurrentPosition(
          function getinfo(options) {
            let city = options.address.city;
            that.LocationCity = city;
            that.lng = options.point.lng;
            that.lat = options.point.lat;
            that
              .getsocial_subsidy({
                lat: options.point.lat,
                lng: options.point.lng,
                area: "",
                member_id: that.userinfo.id,
              })
              .then((res) => {
                if (res.SuccessCode == 200) {
                  that.list = res.ResponseBody;
                }
              });
          },
          function (e) {
            that.LocationCity = "定位失败";
            that.$toast.success("定位失败");
          },
          { provider: "baidu" }
        );
      });
    },
    daohang(item) {
      window.location.href = `http://api.map.baidu.com/marker?location=${item.latitude},${item.longitude}&title=${item.name}&content=${item.address}&output=html&src=yourComponyName|yourAppName`;
    },
    collection(item) {
      if (item.collection == 1) {
        this.getcancel_collection({
          news_id: item.id,
          member_id: this.userinfo.id,
          type: 3,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$toast.success("取消成功");
            item.collection = 0;
          } else {
            this.$toast.fail(res.Message);
          }
        });
      } else {
        this.getcollection({
          news_id: item.id,
          type: 3,
          member_id: this.userinfo.id,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$toast.success("收藏成功");
            item.collection = 1;
          } else {
            this.$toast.fail(res.Message);
          }
        });
      }
    },
  },
  mounted() {
    this.cityss = city;
    this.getbanner({}).then((res) => {
      if (res.SuccessCode == 200) {
        this.img = res.ResponseBody;
      }
    });
    this.city();
  },
};
</script>

<style lang='scss' scoped>
.header_img {
  margin-left: 0.2rem;
  width: 7.1rem;
  height: 1.96rem;
}
.title {
  padding: 0 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .k {
    margin-right: 0.1rem;
    width: 0.07rem;
    height: 0.3rem;
    background: #458aec;
  }
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.search {
  padding: 0 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-left: 0.24rem;
  margin-top: 0.28rem;
  width: 7.02rem;
  height: 0.88rem;
  background: #ffffff;
  box-shadow: 0px 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
  border-radius: 0.09rem;
  img {
    width: 0.6rem;
    height: 0.6rem;
  }
  .qy {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000;
    margin-left: 0.22rem;
  }
  span {
    margin-left: 0.31rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #808080;
  }
}
.list {
  padding: 0.24rem;
  box-sizing: border-box;
  .item {
    margin-bottom: 0.22rem;
    background: #ffffff;
    box-shadow: 0rem 0.05rem 0.14rem 0.03rem rgba(148, 148, 148, 0.23);
    border-radius: 0.09rem;
    .top {
      display: flex;
      justify-content: space-between;
      padding: 0.18rem 0 0.4rem 0;
      .top_1 {
        display: flex;
        align-items: center;
        img {
          width: 0.55rem;
          height: 0.41rem;
        }
        span {
          margin-left: 0.19rem;
          font-size: 0.26rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2978ee;
        }
      }
      .top_2 {
        display: flex;
        align-items: center;
        img {
          width: 0.4rem;
          height: auto;
        }
        span {
          margin-right: 0.42rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2978ee;
        }
      }
    }
    .center {
      display: flex;
      padding: 0 0.42rem;
      .center_1 {
        img {
          margin-right: 0.39rem;
          width: 1.85rem;
          height: 1.31rem;
        }
      }
      .center_2 {
        font-size: 0.24rem;
        line-height: 0.47rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #808080;
      }
    }
    .footer {
      display: flex;
      margin-top: 0.22rem;
      height: 0.76rem;
      border-top: 0.01rem solid #e7e7e7;
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2978ee;
      .footer_1 {
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e7e7e7;
        img {
          width: 0.35rem;
          margin-right: 0.15rem;
        }
      }
      .footer_2 {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e7e7e7;
        color: #2978ee;
        img {
          width: 0.35rem;
          margin-right: 0.15rem;
        }
      }
      .footer_3 {
        flex: 3;

        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 0.35rem;
          margin-right: 0.15rem;
        }
      }
    }
  }
}
</style>